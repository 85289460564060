import { contentTranscripts } from './custom/content-transcripts';
import { dynamicProfileClass } from './custom/dynamic-profile-class';
import { mobileParentCategoryPage } from './custom/mobile-parent-categories';
import { monitorNegativeRatingComment } from './custom/monitor-negative-rating-comment';
import { handleContentPrint } from './custom/content-print';
import { initializeSearch } from './custom/search';
import { trackFirstContent } from './custom/track-first-content';
import { disableDefaultTooltips } from './custom/disable-default-tooltips';
import { changeContentLinksTarget } from './custom/change-content-links-target';
import { setupIframeHeightMonitor } from './custom/iframe-height-monitor';

/**
 * Executes the custom scripts on page load
 */
export default function initiated() {
  // Handle iframe events and send data to parent window
  setupIframeHeightMonitor();

  // Initialize custom search logic
  initializeSearch();

  // Track the first content on category pages
  trackFirstContent();

  // Monitor negative rating comment input
  monitorNegativeRatingComment();

  // Change the body class depending on the profile param to display different themes
  dynamicProfileClass();

  // Monitors and handles the mobile parent category page
  mobileParentCategoryPage();

  // Handle content transcription collapse and expand toggle
  contentTranscripts();

  // Handle content printing
  handleContentPrint();

  // Disables the default browser tooltip on hover
  disableDefaultTooltips();

  // Change contents links target
  changeContentLinksTarget();
}
