const socialSecurityErrorMessage = "Veuillez supprimer votre numéro de sécurité sociale pour envoyer votre commentaire.";
const blacklistErrorMessage = "Les caractères spéciaux suivants ne sont pas acceptés : « » ‘ /  > < _ # % ~";
const maxCharsErrorMessage = "La limite de caractères autorisée est atteinte.";

/**
 * Monitors and updates the character count of negative rating comments and enables/disables the submit button.
 */
export function monitorNegativeRatingComment() {
  const maxChars = 500;

  // Select all textareas for comment feedback
  const textareas = document.querySelectorAll('.comment__feedback__textarea');

  if (textareas) {
    // Add input event listener to each textarea
    textareas.forEach((textarea) => {
      textarea.addEventListener('input', () => {
        handleInput(textarea, maxChars);
      });
    });
  }
}

function handleInput(textarea, maxChars) {
  const textareaParentElement = textarea.parentNode.parentNode;
  if (!textareaParentElement) return;

  const charCount = textarea.value.length;
  const submitButton = textareaParentElement.querySelector('.comment_submit_button');
  const countLabel = textareaParentElement.querySelector('.comment__feedback__charcount');
  const errorLabel = textareaParentElement.querySelector('.comment__feedback__error');

  // Update character count display
  updateCharCount(charCount, maxChars, countLabel);

  // Check for char count error
  const hasCharCountError = checkCharCountError(charCount, maxChars);

  // Check for social security word error
  const hasSocialSecurityError = checkSocialSecurityError(textarea.value);

  // Check for blacklisted word error
  const hasBlacklistedWordError = checkBlacklistWordError(textarea.value);

  // Handle errors
  handleErrors(hasCharCountError, hasSocialSecurityError, hasBlacklistedWordError, textareaParentElement, submitButton, charCount, errorLabel);
}

function updateCharCount(charCount, maxChars, countLabel) {
  countLabel.innerHTML = `${charCount}/${maxChars} caractères`;
}

function checkCharCountError(charCount, maxChars) {
  return charCount >= maxChars;
}

function checkSocialSecurityError(comment) {
  const shortNIRRegExp = /([1-478][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}))/;
  const longNIRRegExp = /([1-478][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})[0-9]{2})/;
  return shortNIRRegExp.test(comment) || longNIRRegExp.test(comment);
}

function checkBlacklistWordError(comment) {
  const blacklistRegExp = /[«»‘/><_#%~]/;
  return blacklistRegExp.test(comment);
}


function handleErrors(hasCharCountError, hasSocialSecurityError, hasBlacklistedWordError, textareaParentElement, submitButton, charCount, errorLabel) {
  if (hasCharCountError || hasSocialSecurityError || hasBlacklistedWordError) {
    textareaParentElement.classList.add('inbenta-km__rating__comment--error');
  } else {
    textareaParentElement.classList.remove('inbenta-km__rating__comment--error');
  }
  
  // Enable or disable the submit button based on character count
  if (hasSocialSecurityError || hasBlacklistedWordError) {
    submitButton.classList.add('inbenta-km-button—-disabled');
    submitButton.style.cursor = "not-allowed";
  } else if (charCount > 0 && !hasSocialSecurityError && !hasBlacklistedWordError) {
    submitButton.classList.remove('inbenta-km-button—-disabled');
    submitButton.style.cursor = "pointer";
  } else if (charCount === 0) {
    submitButton.classList.add('inbenta-km-button—-disabled');
    submitButton.style.cursor = "not-allowed";
  }

  // Force the button to "refresh" in the DOM so the cursor updates in real time
  submitButton.offsetWidth;

  if (hasCharCountError) {
    errorLabel.innerHTML = maxCharsErrorMessage;
    return;
  }
  if (hasSocialSecurityError) {
    errorLabel.innerHTML = socialSecurityErrorMessage;
    return;
  }
  if (hasBlacklistedWordError) {
    errorLabel.innerHTML = blacklistErrorMessage;
    return;
  }
}
