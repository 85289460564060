/**
 * Change the body class based on the profile parameter and set the statut param to all the links HREF
 */
export function dynamicProfileClass() {
  const urlObject = new URL(window.location.href);
  const profile = urlObject.searchParams.get('statut') ?? null;
  const bodyElement = document.querySelector('.inbenta-helpsite__body');
  if (bodyElement && profile) {
    bodyElement.classList.add(profile);

    // Add ?statut params to all HREFs so the profile is persistent while browsing
    addStatutParamToLinks(profile)

    // Add ?statut param value to the search form hidden input 
    addStatutValueToSearchForm(profile);
  }
} 

function addStatutParamToLinks(profile) {
  const links = document.querySelectorAll("a");
  if (!links) return;

  const currentDomain = window.location.hostname;

  links.forEach((link) => {
    const currentHref = link.getAttribute("href");
    if (currentHref) {
      const url = new URL(currentHref, window.location.href);

      if (url.hostname === currentDomain) {
        const searchParams = new URLSearchParams(url.search);

        searchParams.set("statut", profile);
        url.search = searchParams.toString();
        link.setAttribute("href", url.toString());
      }
    }
  });
}

function addStatutValueToSearchForm(profile) {
  const input = document.querySelector("#search-statut-value");
  if (!input) return;
  input.value = profile;
}
