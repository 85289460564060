/**
 * Change the target of content links in case they are empty
 */
export function changeContentLinksTarget() {
  const contentLinks = document.querySelectorAll('.body__contents__item a');

  if (contentLinks) {
    contentLinks.forEach(link => {
      if (!link.getAttribute('target')) {
        link.setAttribute('target', '_parent');
      }
    });
  }
}
