/**
 * Adds event listeners for specified events and posts the current 
 * body scroll height to the parent window when any of the events occur inside the iframe.
 */
export function setupIframeHeightMonitor() {
  // Set the Iframe domain the same as the parent to allow communication
  try {
    document.domain = 'lassuranceretraite.fr';
  } catch (error) {
    // ignore since this will throw errors in our environments
  }

  // Set the document body's height to max-content if in iFrame
  if (window !== window.parent) {
    document.body.style.cssText = 'height: max-content !important;';
  }

  // Post message to the parent window with height on any of these events
  const events = ["click", "mousemove", "mouseleave", "mouseover"];
  events.forEach(function (eventType) {
    document.addEventListener(eventType, function () {
      window.parent.postMessage(document.body.scrollHeight, "*");
    });
  });
}
