import { click } from '../helpers/tracking';

/**
 * Tracks the first content in the category section.
 */
export function trackFirstContent() {
  const element = document.querySelector('.automaticly-expanded .inbenta-km__faq__title');

  if (element) {
    const trackingKey = element.getAttribute('data-tracking');
    const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
    click(trackingKey, trackingData);
  }
}
