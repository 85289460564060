/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import bindClickEvent from '../helpers/clicks';

export default class Clicks {
  constructor(options = {}) {
    this.options = Object.assign(
      {},
      {
        selector: 'a[data-tracking], div[data-tracking]'
      },
      options
    );

    this.#init();
  }

  /**
   * Initialize module
   */
  #init() {
    const trackingLinks = document.querySelectorAll(this.options.selector);
    trackingLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const { currentTarget } = event;
        const container = Clicks.#findParent(currentTarget);
        const faqs = Clicks.#findParent(container, 'inbenta-km__faqs');

        if (faqs.classList.contains('inbenta-km__faqs--toggle')) {
          // should handle the content toggle
          // find the currently opened content
          const opened = faqs.querySelector('.inbenta-km__faq--active');

          if (opened && container && opened === container) {
            // simply close the content
            container.classList.toggle('inbenta-km__faq--active');
          } else {
            // close the opened content & open the clicked one
            if (opened) {
              opened.classList.toggle('inbenta-km__faq--active');
            }
            container.classList.toggle('inbenta-km__faq--active');

            // send click
            bindClickEvent(currentTarget);
          }
        } else {
          // should just send the click and navigate to the content link
          bindClickEvent(currentTarget);
        }
      });
    });
  }

  /**
   * Find and return the parent `inbenta-km__faq` from the given element
   *
   * @param {HTMLElement|Element} element Element to start the search
   * @param {string} classElement Class to check to find the parent element
   * @returns {HTMLElement|Element} Parent element
   */
  static #findParent(element, classElement = 'inbenta-km__faq') {
    const parent = element.parentElement;
    if (parent.classList.contains(classElement)) {
      return parent;
    }
    return Clicks.#findParent(parent);
  }
}
