/**
 * Handles content transcription collapse and expand toggle
 */
export function contentTranscripts() {
  const transcripts = document.querySelectorAll('.content-transcript-title');

  transcripts.forEach((transcript) => {
    transcript.addEventListener('click', function () {
      transcript.parentNode.classList.toggle('content-transcript-wrapper-collapsed')
    });
  });
}
