/**
 * Initializes the custom search logic.
 */
export function initializeSearch() {
  const input = document.querySelector('#inbenta-km__search__form__input');
  const icon = document.querySelector('#search-clear-icon');

  if (!input || !icon) return;

  // On load, run the toggleIconVisibility to properly display the icon on page refresh
  toggleIconVisibility(icon, input.value);

  icon.addEventListener('click', () => {
    resetSearchInput(input);
    clearAutocompleter();
  });

  input.addEventListener('input', () => {
    toggleIconVisibility(icon, input.value);
  });
}

/**
 * Resets the search input.
 *
 * @param {HTMLInputElement} input - The input element to reset.
 */
function resetSearchInput(input) {
  input.value = '';

  const changeEvent = new Event('input', { bubbles: true });
  input.dispatchEvent(changeEvent);
}

/**
 * Toggles the visibility of an icon based on the input value.
 *
 * @param {HTMLElement} icon - The icon element to toggle.
 * @param {string} inputValue - The current input value.
 */
function toggleIconVisibility(icon, inputValue) {
  icon.classList.toggle('hidden', inputValue === '');
}

/**
 * Clear the autocompleter element.
 */
function clearAutocompleter() {
  const autocompleter = document.querySelector('.inbenta-km__autocompleter');

  if (autocompleter) {
    autocompleter.innerHTML = '';
  }
}