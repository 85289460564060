/**
 * Monitors and handles the mobile version of the parent category page.
 * If the user is on mobile, we can't display the sidebar + content, we should only display one or the other.
 * Since in the backend we cannot determine if it's mobile or not, we have to do it via JS.
 * This code basicly hides the sidebar or the content depending if the "#m" is present in the URL.
 * In the mobile version of the sidebar a "#m" is appended to the links to determine if the user has already selected a subcategory.
 * "#m" present = user has already selected the subcategory, else need to show the sidebar so the user can select the subcategory.
 */
export function mobileParentCategoryPage() {
  const hash = window.location.hash;
  const isParentCategory = document.querySelector('.categories-mobile-only.parent-category');

  if (isParentCategory && hash !== '#m') {
    // This means we are in a parent category page to select the subcategory so we should hide the content
    const content = document.querySelector('.categories-mobile-only .content');
    if (content) content.style.display = 'none';

    // Since the mobile sidebar links have a "#m" in the href, we need to force the location and reload (when the current page is already the target page)
    const links = document.querySelectorAll('.categories-mobile-only .inbenta-km__category__sidebar');
    links.forEach((link) => {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        const href = link.getAttribute('href');
        window.location.href = href;
        window.location.reload();
      });
    });

    return;
  }

  if (hash === '#m') {
    // This means we have already selected the subcategory and should only display the content
    const sidebar = document.querySelector('.categories-mobile-only .sidebar');
    if (sidebar) sidebar.style.display = 'none';
  }
}
