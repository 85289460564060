import { click } from './tracking';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 */
export default function bindClickEvent(element) {
  const trackingKey = element.getAttribute('data-tracking');
  const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  let href = element.getAttribute(
    element.nodeName === 'A' ? 'href' : 'data-href'
  );

  if (element.classList.contains('inbenta-km__autocompleter__link')) {
    trackingData.autocomplete = true;
    trackingData.query = document.getElementById(
      'inbenta-km__search__form__input'
    ).value;
  }

  // Handle category toggle and tracking
  let isToggleClick = false;
  const parentElement = element.parentNode ?? null;
  if (parentElement?.classList?.contains('inbenta-km__faq--active')) {
    isToggleClick = true;
    parentElement.classList.toggle('inbenta-km__faq--active');
  }

  if (!isToggleClick) {
    if (parentElement?.classList?.contains('inbenta-km__faq')) {
      parentElement.classList.toggle('inbenta-km__faq--active');
    }

    click(trackingKey, trackingData).then((response) => {
      // redirect after the click
      if (element.nodeName === 'A') {
        if (response.length) {
          href = response;
        }

        // Check if target is blank and open in a new window
        const target = element.getAttribute('target');
        if (target === '_blank') {
          window.open(href, '_blank');
        } else {
          window.location.href = href;
        }
      }
    });
  }
}
