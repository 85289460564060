/**
 * Add event listeners to the print buttons
 */
export function handleContentPrint() {
  const buttons = document.querySelectorAll(".print-content-button");

  if (!buttons) return;

  buttons.forEach((button) => {
    button.addEventListener("click", printContents);
  });
}

/**
 * Print all the expanded contents
 */
function printContents() {
  // Create a new print window
  const printWindow = window.open("", "_blank");

  printWindow.document.open();

  // Update the printWindow href to the current url and print on load
  printWindow.onload = () => {
    printWindow.history.pushState(null, "", window.location.href);
    printWindow.print();
    printWindow.close();
  };

  // Build the basic HTML template
  printWindow.document.write(`<html><head></head><body></body></html>`);

  // Get the head element of the new window
  const printHead = printWindow.document.head;

  // Copy the content of the head from the current page to the new window's head as it will contain all the CSS needed for the print to work
  const head = document.head;
  for (let i = 0; i < head.childNodes.length; i++) {
    const node = head.childNodes[i].cloneNode(true);
    printHead.appendChild(node);
  }

  // Prepare the new window body
  const body = printWindow.document.body;
  body.innerHTML =
    '<div class="inbenta-helpsite"><div class="inbenta-helpsite__body inbenta-km" id="inbenta-body"><div class="inbenta-km__categories__wrapper"><div class="inbenta-km__categories__wrapper__row"><div class="inbenta-km__categories__wrapper__row__categories categories-desktop-only"><div class="inbenta-km__categories content"><div class="inbenta-km__categories__container"><div class="container__category-contents"><div class="inbenta-km__faqs"></div></div></div></div></div></div></div></div></div>';

  // Detect the current page and get the parent
  const parent = findPrintParentElement();

  const children = Array.from(parent.children);
  const printWindowCategoriesParent = body.querySelector(".inbenta-km__faqs");

  // Append the expanded contents to the printWindow
  children.forEach((child) => {
    if (child.classList.contains("inbenta-km__faq--active")) {
      const contentClone = printWindow.document.createElement("div");
      contentClone.innerHTML = child.innerHTML;
      printWindowCategoriesParent.innerHTML += `<div class="inbenta-km__faq inbenta-km__faq--active">${contentClone.innerHTML}</div>`;
    }
  });

  // Remove all ratings from printWindow
  const ratings = printWindow.document.querySelectorAll(".body__rating");
  if (ratings) {
    ratings.forEach((element) => {
      element.remove();
    });
  }

  // Set the correct profile class in the printWindow
  const urlParams = new URLSearchParams(window.location.search);
  const statutParam = urlParams.get("statut");
  if (statutParam) {
    const inbentaBody = printWindow.document.getElementById("inbenta-body");
    inbentaBody.classList.add(statutParam);
  }

  printWindow.document.close();
}

/**
 * Finds and returns the parent element for printing content.
 */
function findPrintParentElement() {
  const categoryPage = document.querySelector(
    ".categories-desktop-only .container__category-contents .inbenta-km__faqs"
  );
  const resultsPage = document.querySelector(
    ".inbenta-km__results .inbenta-km__results__extended .inbenta-km__faqs"
  );
  const contentPage = document.querySelector(
    ".single-content-page"
  );

  if (categoryPage) {
    return categoryPage;
  } else if (resultsPage) {
    return resultsPage;
  } else if (contentPage) {
    return contentPage;
  }

  throw new Error("Failed to detect current print page");
}
