/**
 * Disables the default browser tooltip on hover since we want to display a custom CSS tooltip
 */
export function disableDefaultTooltips() {
  const anchorsWithTitles = document.querySelectorAll("a[title]");

  if (!anchorsWithTitles) return;

  anchorsWithTitles.forEach((anchor) => {
    let originalTitle = anchor.getAttribute("title");

    anchor.addEventListener("mouseover", (event) => {
      event.preventDefault();
      anchor.setAttribute("data-original-title", originalTitle); // Store the original title in a data attribute
      anchor.setAttribute("title", ""); // Set the title attribute to an empty string to prevent the default tooltip
    });

    anchor.addEventListener("mouseout", () => {
      const originalTitle = anchor.getAttribute("data-original-title");
      anchor.setAttribute("title", originalTitle); // Restore the title
    });
  });
}
